import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { IAPILegacyOS, LegacyOS } from './legacy-os';
import { Utilities } from '../../../../class/utilities';
import { DataService } from '../../../../service/data.service';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { MkgOS } from 'src/app/class/mkg-os';

// const FAKE_DATA: IAPILegacyOS[] = [{
//     numeroOS: '001',
//     dataAbertura: '2022-07-24T13:28:52.198Z',
//     dataFechamento: '2022-07-28T13:28:52.198Z',
//     cliente: "Fulano de tal*45999055238*08154393930",
//     placa: "ART1218",
//     pecas: [
//       "001*AMORTECEDOR DIANTEIRO*1*230*0*230*230"
//     ],
//     servicos: [
//       "002*TROCA DE AMORTECEDOR*01*35.50*0*35.50*35.50"
//     ],
//     servicosTerceiros: [
//       "002*TROCA DE AMORTECEDOR*01*35.50*0*35.50*35.50"
//     ],
//     valorBrutoPecas: 230,
//     descontoPecas: 0,
//     valorLiquidoPecas: 230,
//     valorBrutoServico: 35.5,
//     desscontoServico: 0,
//     valorLiquidoServico: 35.5,
//     valorBrutoOS: 260.5,
//     decontoOS: 0,
//     valorLiquidoOS: 260.5,
//     observacao: "Teste OS legada",
//     KM: ""
//   },

//   {
//     numeroOS: '002',
//     dataAbertura: '2022-07-21T13:28:52.198Z',
//     dataFechamento: '2022-07-26T13:28:52.198Z',
//     cliente: "Ciclano*4599999999*12312312000199",
//     placa: "AMG2020",
//     pecas: [
//       "002*AMORTECEDOR DIANTEIRO*1*230*0*230*230",
//       "002*AMORTECEDOR TRASEIRO*2*100*10*200*190"
//     ],
//     servicos: [
//       "002*TROCA DE AMORTECEDOR*02*35.50*0*71.00*71.00"
//     ],
//     servicosTerceiros: [
//       "001*SERVIÇO TERCEIRIZADO DE SOLDA*01*100*0*100*100"
//     ],
//     valorBrutoPecas: 430,
//     descontoPecas: 10,
//     valorLiquidoPecas: 420,
//     valorBrutoServico: 71,
//     desscontoServico: 0,
//     valorLiquidoServico: 71,
//     valorBrutoOS: 491,
//     decontoOS: 1,
//     valorLiquidoOS: 490,
//     observacao: "Teste dois itens na OS legada",
//     KM: ""
//   },

//   {
//     numeroOS: '003',
//     dataAbertura: '2022-07-20T13:28:52.198Z',
//     dataFechamento: '2022-07-27T13:28:52.198Z',
//     cliente: "BELTRANO*4599999999*12345678910",
//     placa: "ABC1234",
//     valorBrutoPecas: 0,
//     descontoPecas: 0,
//     valorLiquidoPecas: 0,
//     valorBrutoServico: 0,
//     desscontoServico: 0,
//     valorLiquidoServico: 0,
//     valorBrutoOS: 0,
//     decontoOS: 0,
//     valorLiquidoOS: 0,
//     observacao: "Teste OS legada sem itens",
//     KM: ""
//   }

// ]

@Injectable({
  providedIn: 'root'
})
export class LegacyService {

  private _isFromMatrix = false;


  constructor(
    private dataService: DataService,
    private http: HttpClient
  ) { }

  /**
   * @param plate the vehicle plate to filter
   */
  async getAll(plate?: string): Promise<LegacyOS[]> {
    let legacies = [];
    const url = `${environment.mkgoURL}/api/v1/valor`;
    let options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    if (plate) {
      options['params'] = new HttpParams({
        fromObject: {
          placa: plate
        }
      })
    }
    const resp: { valores: IAPILegacyOS[] } = await this.http.get(url, options).pipe(first()).toPromise() as any;
    // for (const apiData of FAKE_DATA) {
    for (const apiData of resp.valores) {
      const OS = new LegacyOS(apiData)
      legacies.push(OS)
    }
    return legacies
  }

  async registerLegacy(OS: MkgOS, companyId: string) {
    const url = `${environment.mkgoURL}/api/v1/valor`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const legacy: IAPILegacyOS = {
      company: companyId,
      cliente: `${OS.clientObject.name}*${OS.clientObject.phone1}*${OS.clientObject.document}`,
      placa: OS.vehicle.plate,
      dataAbertura: OS.createdAt,
      dataFechamento: OS.evaluationDate || '',
      numeroOS: OS.codeSystem.toString(),
      decontoOS: Utilities.calcPercentage(OS.generalDiscount || 0, OS.grossValue) || 0,
      descontoPecas: Utilities.calcPercentage(OS.discountParts, OS.grossValueParts) || 0,
      desscontoServico: Utilities.calcPercentage(OS.discountLabors, OS.grossValueLabors) || 0,
      observacao: OS.observation || "",
      valorBrutoOS: OS.grossValue,
      valorBrutoPecas: OS.grossValueParts,
      valorBrutoServico: OS.grossValueLabors,
      valorLiquidoOS: OS.liquidValue,
      valorLiquidoPecas: OS.liquidValueParts,
      valorLiquidoServico: OS.liquidValueLabors,
      KM: OS.km.toString(),
      pecas: OS.parts ? OS.parts.slice().map(roPart => {
        return `${roPart.part.code}*${roPart.part.description}*${roPart.amount()}*${roPart.saleValue()}*${roPart.additionalDiscount}*${roPart.grossValue()}*${roPart.liquidValue}`
      }) : [],
      servicos: OS.labors ? OS.labors.slice().map(roLabor => {
        return `${roLabor.labor.code}*${roLabor.labor.description}*${roLabor.amount}*${roLabor.saleValue}*${roLabor.additionalDiscount}*${roLabor.saleValue * roLabor.amount}*${roLabor.liquidValue}`
      }) : []
    }

    const resp = await firstValueFrom(this.http.post(url, { valores: [legacy] }, options));
    return resp
  }
}
